.summary {
    background-color: white;
    border-radius: 8px;
    padding: 24px 16px 32px;
    margin-bottom: 24px;
    h2 {
        font-size: 20px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #041323;
        margin-bottom: 24px;
    }

    .summaryContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        grid-gap: 32px;
        .summaryBox {
            max-width: 248px;
            width: 100%;
            border: 1px solid #dddde9;
            border-radius: 8px;
            padding: 16px 16px 14px;
            h3 {
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #11142d;
                margin-bottom: 0;
            }
            h4 {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #041323;
                margin-bottom: 4px;
            }
        }
        @media screen and(max-width: 1170px) {
            justify-content: center;
        }
    }
}

.pieChartSection {
    border-radius: 8px 8px 0 0;
    background-color: #fff;
    padding: 24px 16px;
    h2 {
        font-size: 20px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #041323;
        margin-bottom: 24px;
    }
    .charts {
        display: flex;
        column-gap: 32px;
        justify-content: space-between;
        .chartWrapper {
            padding-top: 16px;
            padding-bottom: 30px;
            width: 100%;
            display: grid;
            place-items: center;
            border: 1px solid #dddde9;
            border-radius: 8px;
            .chart {
                width: calc(60% + 20px);
            }
        }
    }
}

.exchangeTable {
    border-radius: 0 0 8px 8px;
    background-color: #fff;
    padding: 24px 16px 32px;
    margin-bottom: 24px;
    .filterSection {
        display: flex;
        column-gap: 15px;
        align-items: center;
        margin-bottom: 20px;
        span {
            font-size: 16px;
        }
    }
    .tableWrapper {
        overflow-x: auto;
        .table {
            width: 100%;
            font-size: 14px;
            white-space: nowrap;
            .tableHeadRow {
                background-color: #f6f5f8;
                height: 72px;
                th {
                    font-size: 16px;
                    font-weight: 600;
                    padding: 16px;
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6) {
                        min-width: 217px;
                    }
                }
                .sortButton {
                    &:hover {
                        background-color: #dddddd;
                        cursor: pointer;
                        transition: 0.3s all;
                    }
                    i {
                        text-decoration: none;
                        -webkit-text-stroke: 1px;
                        color: #041323;
                        margin-left: 5px;
                    }
                }
            }
            .exchangeColumn {
                display: flex;
                align-items: center;
                i {
                    color: #daa520;
                    font-size: 16px;
                    cursor: pointer;
                }
            }
            .exchangeRow {
                border-bottom: 1px solid #e2e2ea;
                height: 80px;
                .exchangeText {
                    text-transform: capitalize;
                    margin-right: 5px;
                }
                td {
                    padding: 16px;
                }
            }
            .tob {
                .tobValues {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    span {
                        &:nth-child(1) {
                            color: #f97066;
                        }
                        &:nth-child(2) {
                            color: #85e13a;
                        }
                    }
                }
                .tobChart {
                    position: relative;
                    .line {
                        position: absolute;
                        border-left: 1px solid #000000;
                        left: 50%;
                        z-index: 1;
                        height: 16px;
                        top: -50%;
                    }
                    .tobProgress {
                        height: 8px;
                        width: 80%;
                        margin: 0 auto;
                        div {
                            background-color: #f97066;
                        }
                    }
                }
            }
        }
        img {
            border-radius: 4px;
            width: 24px;
            margin-right: 8px;
        }
    }
}

.exchanges {
    background-color: #fff;
    padding: 24px 16px;
    border-radius: 8px;
    h2 {
        margin-bottom: 24px;
    }
    .totalBalances {
        display: flex;
        column-gap: 32px;
        margin-bottom: 32px;
        flex-direction: row;
        flex-wrap: wrap;
        .balanceBox {
            margin-top: 10px;
            padding: 16px 16px 14px;
            border: 1px solid #dddde9;
            border-radius: 8px;
            width: calc(50% - 16px);
            flex-basis: 23%;
            h4 {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #041323;
            }
            h3 {
                margin-bottom: 0;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #11142d;
            }
        }
        .expand {
            flex: 1;
        }
    }
    .exchangesBalancesSection {
        display: flex;
        gap: 20px 32px;
        flex-wrap: wrap;
        .coinBox {
            padding: 22px 16px 16px;
            width: calc(50% - 16px);
            background-color: #f6f5f8;
            border-radius: 8px;
            .header {
                display: flex;
                align-items: center;
                column-gap: 12px;
                margin-bottom: 16px;
                img {
                    width: 40px;
                }
                .exchangeName {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 120%;
                    letter-spacing: 0.005em;
                    color: #041323;
                    text-transform: capitalize;
                }
            }
            .balances {
                display: flex;
                flex-wrap: wrap;
                column-gap: 16px;
                .balanceBox {
                    margin-top: 10px;
                    background-color: #fff;
                    padding: 16px 16px 14px;
                    border: 1px solid #dddde9;
                    border-radius: 8px;
                    width: calc(50% - 8px);
                    flex-basis: 48%;
                    h4 {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #041323;
                    }
                    h3 {
                        margin-bottom: 0;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                        color: #11142d;
                    }
                }
            }
        }
        @media screen and(max-width: 768px) {
            .coinBox {
                width: 100%;
            }
        }
    }
}
